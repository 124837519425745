$systemFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

.navigation {
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: all .35s ease;

  &--open {
    @media screen and (max-width: 640px) {
      transform: translateX(0);
    }
  }

  .header-logo {
    display: none;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }

  &__actions {
    display: flex;
    flex: 16;
  }

  &__icon {
    animation: nav-button-colors 45s infinite;
    color: #585af3;
    font-size: 1.75rem;
    z-index: 1;

    &--open {
      display: flex;
    }

    &--close {
      display: none;
      transition: all .5s ease;

      @media screen and (max-width: 640px) {
        display: flex;
      }
    }
  }

  &__list {
    display: flex;
    flex: 12;
    margin: 0;
    padding: 0;
    position: relative;

    &--primary {
      padding-right: .75rem;
    }

    &--secondary {
      padding-left: .75rem;
    }

    .item {
      border-bottom: 1px solid transparent;
      display: flex;
      list-style: none;
      margin: 0;
      margin-left: 1.375rem;
      padding: 0;
      position: relative;

      &:first-child {
        margin-left: 0;
      }

      &__dropdown {
        background-color: #4384ff;
        border-radius: 0.375rem;
        display: flex;
        font-size: 0.825rem;
        font-weight: 500;
        padding: 0.25rem;
        position: absolute;
        left: 50%;
        top: calc(100% + 0.5rem);
        transform: translateX(-50%);

        a {
          background-color: #ffffff00;;
          border-radius: 0.25rem;
          color: #19192c;
          margin-right: 0.25rem;
          padding: 0.25rem 0.375rem;
          text-decoration: none;

          &:hover {
            color: #ffffff;
          }

          &.active {
            background-color: #0e121ea6;
            color: #c1c0ff;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &__link {
        align-items: center;
        border-radius: 0.25rem;
        color: #ffffff;
        display: flex;
        font-size: 1rem;
        padding: 0.325rem;
        text-decoration: none;
        letter-spacing: -.5px;
        font-family: 'Kumbh Sans', sans-serif;

        .icon {
          font-size: 0.875rem;
          margin-left: 0.5rem;
        }

        &--action {
          color: #4284ff;
          font-size: .9rem;
        }
      }
    }
  }

  @media screen and (max-width: 640px) {
    display: flex;
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #070d17;
    width: 100%;
    box-sizing: border-box;
    align-items: flex-start;
    flex-direction: column;
    transform: translateX(-100%);

    &--open {
      transform: translateX(0);
    }

    .navigation {
      &__icon {
        padding: 1.25rem;
        position: absolute;
        right: 0;

        &--open {
          display: none;
        }

        &--close {
          display: inline;
        }
      }

      &__list {
        flex-direction: column;
        padding: 0;
        width: 100%;

        .item {
          border-bottom: 1px solid #545af314;
          margin-left: 0;
          padding: 0.75rem 1.25rem;

          &__link {
            font-size: 1.125rem;
          }
        }
      }
    }

    .navigation--icon {
      padding: 1.25rem;
      position: absolute;
      right: 0;
    }

    .header {
      &-logo {
        display: inline;
        font-size: 1.25rem;
        padding: 1.25rem;
      }

      &-button {
        font-size: 1.125rem;
        margin: 1.25rem;
        padding: 0.75rem;
        width: calc(100% - 2.5rem);
      }
    }
  }
}

.header-button {
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
  font-family: 'Kumbh Sans', sans-serif;
  padding: 0.5rem 1.25rem;
  border-radius: 30px;
  font-size: 0.875rem;
  letter-spacing: -0.5px;
}

.navigation-dropdown {
  background-color: #4384ff;
  border-radius: 0.375rem;
  display: flex;
  font-size: 0.825rem;
  font-weight: 500;
  padding: 0.25rem;
  position: absolute;
  left: 50%;
  top: calc(100% + 0.5rem);
  transform: translateX(-50%);
  width: 400px;
  padding: 1.25rem;
  box-sizing: border-box;
  transform: translateX(-50%);
  left: 50%;
  flex-direction: column;
  position: absolute;

  &::before {
    content: '';
    border-bottom: 10px solid #4384ff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: 0;
    transform: translate(-50%, -100%);
    left: 50%;
  }

  &__icon {
    background-color: #19192c;
    box-sizing: border-box;
    display: flex;
    font-size: 1.25rem;
    padding: 0.375rem;
    border-radius: 2px;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
    margin-right: 0.625rem;
    color: #4384ff;
  }

  a {
    align-items: center;
    background-color: #ffffff00;;
    border-radius: 0.25rem;
    color: #19192c;
    display: flex;
    margin-right: 0.25rem;
    padding: 0.25rem 0.375rem;
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: 700;

    &:hover {
      color: #ffffff;
    }

    &.active {
      background-color: #0e121ea6;
      color: #c1c0ff;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes nav-button-colors {
  0% {
    color: rgb(152, 114, 255);
  }

  12% {
    color: rgb(0, 117, 255);
  }

  25% {
    color: rgb(114, 255, 232);
  }

  37% {
    color: rgb(114, 255, 130);
  }

  50% {
    color: rgb(249, 114, 114);
  }

  62% {
    color: rgb(255, 70, 129);
  }

  75% {
    color: rgb(255, 114, 114);
  }

  87% {
    color: rgb(255, 114, 114);
  }

  100% {
    color: rgb(255, 114, 179);
  }
}