.notification {
  &-toast {
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-name: reveal-toast;
    border-radius: 0.25rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    left: 50%;
    opacity: 1;
    padding: 1rem;
    position: fixed;
    top: 1rem;
    transform: translateX(-50%);
    width: 94%;
    z-index: 12;

    &--success {
      background-color: #45fe9a;
      color: #021e0f;
    }

    &--fail {
      background-color: #fe4560;
      color: #ffffff;
    }
  }
}

.toast-notification {
  animation-duration: .3s;
  animation-iteration-count: 1;
  animation-name: reveal-toast;
  position: fixed;
  top: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0075ff;
  padding: 1rem;
  border-radius: 4px;
  color: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.21);
  display: flex;
  align-items: center;

  &--dismissed {
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-name: hide-toast;
  }

  &__icon {
    margin-right: 0.5rem;
  }
}

@keyframes reveal-toast {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide-toast {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}