$systemFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$poppins: 'Poppins', sans-serif;
$textLight: #bcbbf89e;

.page {
  height: 100%;
}

.mobile-nav-button {
  align-items: center;
  background-color: transparent;
  border: none;
  display: none;
  font-size: 1.5rem;
  flex-direction: column;
  height: 2.75rem;
  justify-content: center;
  width: 2.75rem;

  @media screen and (max-width: 640px) {
    display: flex;
  }

  .bar {
    background-color: #283151;
    border-radius: 1px;
    display: block;
    height: 0.25rem;
    transform: translate(0, -50%);
    transition: all .45s ease;
    width: 1.325rem;

    &:last-child {
      transform: translate(0, calc(-50% + 0.25rem));
    }
  }

  &--visible {
    .bar {
      transform: translate(0, 50%) rotate(45deg);

      &:last-child {
        transform: translate(0, -50%) rotate(-45deg);
      }
    }
  }
}

.home {
  &-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    padding-bottom: 4rem;
    position: relative;
    width: 100vw;
    z-index: 2;
  }

  &-content {
    align-items: center;
    background-color: #19192c;
    border: .75rem solid transparent;
    border-image-slice: 1;
    border-image-source: linear-gradient(21deg, #604aef 30%,#1687fb 55%,#4cf8d4 85%);
    border-width: .45rem;
    box-sizing: border-box;
    color: #262a48;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    padding: 1.75rem;
    position: relative;
    text-align: center;
    width: 80vw;
    z-index: 1;

    h2 {
      background: linear-gradient(21deg,#604aef 30%,#1687fb 55%,#4cf8d4 85%);
      font-size: 5.25rem;
      margin-top: 2rem;
      text-shadow: 0 0 80px #5553ffdd;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h3 {
      background: linear-gradient(21deg,#604aef 30%,#1687fb 55%,#4cf8d4 85%);
      font-size: 3rem;
      margin-top: 2rem;
      text-shadow: 0 0 80px #5553ffdd;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      box-sizing: border-box;
      color: #c1c0ff;
      font-size: 1.325rem;
      font-weight: 200;
      line-height: 3.5rem;
      padding: 0 1.25rem;
      text-align: center;
    }
  }
}

.box {
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-name: moving-border;
  background-color: #101626;
  border-radius: .325rem;
  border: 1px solid transparent;
  border-image-slice: 1;
  border-image-source: linear-gradient(21deg,rgba(96, 74, 239, .35) 30%,rgba(22, 135, 251, .35) 55%,rgba(76, 248, 212, .35) 85%);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: $systemFont;
  margin: 3rem auto;
  min-height: 40vw;
  padding: 2.25rem;
  position: relative;
  width: 70%;
  z-index: 1;

  &--with-container {
    background-color: #00a4ff17;
  }

  @media screen and (max-width: 1080px) {
    width: 80%;
  }

  @media screen and (max-width: 960px) {
    padding: 1.75rem;
    width: 92%;
  }

  .title {
    color: #ffffff;
    font-family: $poppins;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    margin-bottom: 1.25rem;
    width: 100%;

    @media screen and (max-width: 600px) {
      font-size: 2rem;
      text-align: left;
    }
  }

  .description {
    color: $textLight;
    font-family: $poppins;
    font-size: 1.325rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 2.25rem;
    width: 90%;

    @media screen and (max-width: 600px) {
      font-size: 1.125rem;
      line-height: 1.75rem;
      text-align: left;
      width: 100%;
    }
  }

  .instructions {
    color: $textLight;
    font-family: $poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    margin: 0 auto;
    padding-bottom: 2.25rem;
    width: 90%;
  }
}

.header {
  animation-name: header-underline--smol;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  align-items: center;
  box-shadow: 0 4px 0px 0 #7285ff;
  background-color: #070d18d9;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.75rem 1.25rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 11;
  backdrop-filter: blur(4px);
  transition: all .25s ease;

  &-logo {
    font-family: 'Kumbh Sans', sans-serif;
    color: white;
    font-weight: 500;
    letter-spacing: -1px;
    font-size: 1.375rem;
    margin: 0;
    // background: linear-gradient(347deg, #2967ff, #4680ff, #6fd6ff);
    // background-clip: text;
    // -webkit-text-fill-color: transparent;
  }

  &--sticky {
    animation-name: header-underline;
    padding: 0.75rem 1.25rem;
  }

  @media screen and (max-width: 960px) {
    padding: 1.25rem 4%;
    width: 100%;
  }
}

@keyframes header-underline {
  0% {
    box-shadow: 0 1px 0 0 rgb(152, 114, 255);
  }

  12% {
    box-shadow: 0 1px 0 0 rgb(0, 117, 255);
  }

  25% {
    box-shadow: 0 1px 0 0 rgb(114, 255, 232);
  }

  37% {
    box-shadow: 0 1px 0 0 rgb(114, 255, 130);
  }

  50% {
    box-shadow: 0 1px 0 0 rgb(249, 114, 114);
  }

  62% {
    box-shadow: 0 1px 0 0 rgb(255, 70, 129);
  }

  75% {
    box-shadow: 0 1px 0 0 rgb(255, 114, 114);
  }

  87% {
    box-shadow: 0 1px 0 0 rgb(255, 114, 114);
  }

  100% {
    box-shadow: 0 1px 0 0 rgb(255, 114, 179);
  }
}

@keyframes header-underline--smol {
  0% {
    box-shadow: 0 1px 0 0 rgba(152, 114, 255, 0.1);
  }

  12% {
    box-shadow: 0 1px 0 0 rgba(0, 117, 255, 0.1);
  }

  25% {
    box-shadow: 0 1px 0 0 rgba(114, 255, 232, 0.1);
  }

  37% {
    box-shadow: 0 1px 0 0 rgba(114, 255, 130, 0.1);
  }

  50% {
    box-shadow: 0 1px 0 0 rgba(249, 114, 114, 0.1);
  }

  62% {
    box-shadow: 0 1px 0 0 rgba(255, 70, 129, 0.1);
  }

  75% {
    box-shadow: 0 1px 0 0 rgba(255, 114, 114, 0.1);
  }

  87% {
    box-shadow: 0 1px 0 0 rgba(255, 114, 114, 0.1);
  }

  100% {
    box-shadow: 0 1px 0 0 rgba(255, 114, 179, 0.1);
  }
}

.footer {
  align-items: center;
  background-color: #20386f;
  box-sizing: border-box;
  color: #ffffff;
  font-family: 'eudoxus sans';
  font-size: 0.75rem;
  font-weight: 900;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  padding: 0 0.75rem;
  text-transform: uppercase;
  width: 100%;
  z-index: 1;
}

@keyframes moving-border {
  0% {
    border-image-source: linear-gradient(21deg,rgba(96, 74, 239, .85) 0%,rgba(22, 135, 251, .85) 33%,rgba(76, 248, 212, .85) 66%);
  }

  25% {
    border-image-source: linear-gradient(21deg,rgba(96, 74, 239, .85) 33%,rgba(22, 135, 251, .85) 66%,rgba(76, 248, 212, .85) 99%);
  }

  50% {
    border-image-source: linear-gradient(21deg, rgba(76, 248, 212, .85) 33%, rgba(96, 74, 239, .85) 66%,rgba(22, 135, 251, .85) 99%);
  }

  75% {
    border-image-source: linear-gradient(21deg, rgba(22, 135, 251, .85) 32%, rgba(76, 248, 212, .85) 66%, rgba(96, 74, 239, .85) 99%);
  }

  100% {
    border-image-source: linear-gradient(21deg,rgba(96, 74, 239, .85) 0%,rgba(22, 135, 251, .85) 33%,rgba(76, 248, 212, .85) 66%);
  }
}