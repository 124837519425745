.flexbox-lesson {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  overflow: scroll;
  justify-content: center;
  
  h1 {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
  }
  
  h2 {
    border-top: 1px solid #cccccc;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-top: 1.25rem;
  }
  
  p {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  /**
   * Header
   */
  
  header {
    background-color: #191a34;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    padding: 0.75rem 1.25rem;
  }
  
  .nav-items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-item {
    border-radius: 0.25rem;
    margin-right: 0.25rem;
    padding: 0.5rem;
  }
  
  .nav-item a {
    color: #908bff;
    text-decoration: none;
  }
  
  .nav-item:last-child {
    margin-right: 0;
  }
  
  .nav-item__text {
    display: none;
  }
  
  /**
   * Body
   */
  
  main {
    color: #ffffff;
    height: 100%;
    margin: 0 auto;
    padding: 2.5rem 0;
  }

  /**
  * Tasks
  */

  .task {
    border-radius: 0.375rem;
    box-sizing: border-box;
    display: flex;
    width: 60vw;
    padding: 1.25rem;

    .browser-body {
      color: #000000;
    }
  }

  /**
  * Flex-box
  */

  .task-flex-box {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .task-flex-box__row {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    width: 100%;

    div {
      align-items: center;
      border-right: 1px solid #cccccc;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      padding: 1.25rem;

      &:last-child {
        border-right: none;
      }
    }
  }

  .task-flex-box__column {
    border: 1px solid #cccccc;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    div {
      border-bottom: 1px solid #cccccc;
      display: flex;
      padding: 1.25rem;
      justify-content: center;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .task-1 {
    .flex-row {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
    }
  }

  .task-2 {
    .flex-row {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    .flex-column {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
    }
  }

  .task-3 {
    .flex-row {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        align-items: center;
        border-right: 1px solid #cccccc;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        padding: 1.25rem;
      }
    }

    .flex-column {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
      width: 100%;

      div {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        padding: 1.25rem;
      }
    }
  }

  .task-4 {
    .flex-row {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        align-items: center;
        border-right: 1px solid #cccccc;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        padding: 1.25rem;

        &:last-child {
          border-right: none;
        }
      }
    }

    .flex-column {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
      width: 100%;

      div {
        align-items: center;
        border-bottom: 1px solid #cccccc;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        padding: 1.25rem;
      }
    }
  }

  .task-5 {
    .flex-column {
      border: 1px solid #cccccc;
      display: flex;
      flex-direction: column;
      width: 100%;

      div {
        align-items: center;
        border-bottom: 1px solid #cccccc;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        padding: 1.25rem;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}