.code-editor {
  &-console {
    &__list {
      display: flex;
      flex-direction: column-reverse;
      line-height: 1.5rem;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &::before {
          color: rgba(255, 255, 255, .4);
          content: '$';
          margin-right: .5rem;
        }
      }
    }
  }

  &-css {
    &-reference {
      background-color: #f5f5f5;
      border-radius: .325rem;
      box-shadow: 0 0 2.25rem .325rem rgba(0, 0, 0, .25);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      font-size: .9325rem;
      font-weight: 300;
      margin: 2rem;

      .row {
        display: flex;
        justify-content: space-between;
        line-height: 2rem;
        list-style: none;
        padding: .325rem 1.25rem;

        &--active {
          box-shadow: 0 0 7px 3px #00000066;
          background-color: #740cff;
          color: white;
          font-weight: bold;
          border-top: 4px solid #ffffffe8;
          border-bottom: 4px solid #ffffffe8;
        }

        &:nth-child(even) {
          background-color: rgba(0, 0, 0, .15);

          &--active {
            background-color: #740cff;
          }
        }

        &__text {
          display: flex;
          flex: 12;

          &:first-child {
            flex: 4;
            font-weight: 400;

            &--active {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  &__indicator {
    color: transparent;

    &--valid {
      color: #51ffe7;
    }

    &--invalid {
      color: #e51758;
    }
  }
}