.container {
  background-color: #ffffff;
  width: 100%;
  border-radius: 0.625rem;
  box-sizing: border-box;
  box-shadow: 4px 4px 11px 0px #a3a3a30d;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #f7f7f7;

  &--in-box {
    width: 100%;
    border-radius: 2px;
  }

  &.container--dark {
    .post__header {
      background-color: #283051;
      border-radius: 0;
      padding: 2rem calc(5% + 1.75rem);

      h1 {
        color: #ffffff;
      }

      p {
        color: #acc4ff;
      }
    }

    .lesson-section {
      &__content {
        background-color: #ffffff;
        border-bottom: 1px solid #eceff6eb;
        color: #20386f;
        padding: 1.5rem;

        &--code-demo {
          border-left: 0.5rem solid #4fe0c0;
        }

        p {
          color: #20386f;
        }
      }

      code {
        background-color: #374270;
        border: none;
        color: #c5d5ff;
      }
    }
  }

  &--dark {
    background-color: transparent;
    border: none;
    box-shadow: none;

    .post {
      background-color: #283151;

      .search-highlight {
        background-color: #feff72;
        border-radius: 4px;
        color: #344b80;
        padding: 1px 4px;
      }

      &__search {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 100%;
        background-color: #ebf5ff;

        .filter {
          cursor: pointer;
          font-size: 24px;
          color: #8fa0bf;
          padding: 0 1.25rem;
          background-color: transparent;
          border: none;
          border-left: 1px solid #8fa0bf1a;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 7px 0 33px 0 rgba(0, 0, 0, .1);
          margin-left: 1rem;
          transition: all .15s ease;

          &:hover,
          &:active,
          &:focus {
            color: #20386f;
          }
        }

        .loading {
          color: #8fa0bf;
          font-size: 1.375rem;
        }

        input {
          display: flex;
          background-color: #ebf5ff;
          padding: 1rem 1.25rem;
          border: none;
          border-left: 0.5rem solid #20386f73;
          flex-grow: 1;
          color: #20386f;
          font-family: 'eudoxus sans';
          font-weight: 500;
          outline: 0;
          letter-spacing: -.75px;
          font-size: 1.125rem;

          &:focus {
            border-color: #283151;
          }

          &::placeholder {
            color: #20386f73;
          }
        }
      }

      .list-paragraphs .post__icon {
        background-color: #585af3;
        color: #081021;
        margin-left: 1.25rem;
      }

      &.post--main {
        background-color: transparent;

        .list-paragraphs {
          .list-paragraph {
            background-color: #ffffff;
            border-left: 8px solid #bcdbff4d;
            color: #20386f;
            column-gap: 1.25rem;
            display: grid;
            grid-template-columns: 29fr 1fr;

            &:nth-child(even) {
              a {
                &.heading {
                  color: #83a2ec !important;
                }
              }
            }

            .read-more {
              background-color: #ebf4ff4f;
              border-left: 6px solid #7483a8;
              margin-top: 2rem;
              padding: 0.25rem 1.25rem 1rem;

              &__button {
                background-color: transparent;
                border: none;
                color: #1f386f;
                font-size: 18px;
                height: fit-content;
                padding: 5px;
                width: fit-content;
              }
            }

            &--icon {
              border-bottom: none;
              border-left: 0.25rem solid #5d5efff2;
              border-radius: 0.25rem;
              box-shadow: 0 0 227px 0 #005cff12 inset;
              color: #83a2ec;

              &:nth-child(even) {
                background-color: #eceff6;
                color: #20386f;

                &.list-paragraph--icon {
                  border-bottom: none;
                  border-left: 0.25rem solid #5d5efff2;
                  border-radius: 0.25rem;
                  box-shadow: 0 0 227px 0 #005cff12 inset;
                  color: #83a2ec;
                }

                .post__icon {
                  background-color: #585af3;
                  color: #081021;
                  margin-left: 1.25rem;
                }
              }
            }

            .resume__heading {
              .heading {
                color: #20386f;
              }
            }

            a {
              &.heading {
                color: #83a2ec;
              }
            }

            &__tag {
              background-color: #eceff6;
              color: #20386f;
            }

            &:nth-child(even) {
              background-color: #ffffff;
              color: #20386f;

              .resume__heading {
                .heading {
                  color: #20386f;
                }
              }

              a {
                &.heading {
                  color: #20386f;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    padding: 1.25rem;
  }

  @media screen and (max-width: 640px) {
    border-radius: 0.25rem;
    padding: 0;
  }

  .post {
    background-color: #ffffff;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 4fr 16fr;
    margin-bottom: 1.25rem;
    overflow: hidden;
    text-decoration: none;
    width: 100%;

    @media screen and (max-width: 960px) {
      width: 100%;
    }

    .resume {
      &__heading {
        align-items: center;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 670px) {
          align-items: flex-start;
          flex-direction: column;
        }

        &--subheading {
          flex-direction: row;

          @media screen and (max-width: 670px) {
            font-size: .95rem;
            font-weight: bold;
          }
        }

        .location {
          margin-left: 0.375rem;
        }

        .location-icon {
          font-size: 0.875rem;
          margin-top: -1px;
          opacity: 0.5;
        }

        .location-wrapper {
          align-items: center;
          display: flex;
        }

        &:first-child {
          margin-bottom: 0.625rem;
        }
      }
    }

    .list-paragraphs {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      .list-paragraph {
        background-color: #ffffff;
        border-bottom: 1px solid #eceff6eb;
        color: #20386f;
        padding: 1.5rem;

        &:last-child {
          border-bottom: none;
        }

        &--icon {
          align-items: center;
          border-bottom: 0.25rem solid #ebf4ff;
          border-left: none;
          column-gap: 1.25rem;
          display: flex;
          padding-left: 0;

          @media screen and (max-width: 640px) {
            padding-left: 0.75rem;
          }

          &:hover {
            .post__icon {
              color: #0b121f;
            }
          }
        }

        .heading {
          align-items: center;
          color: #0d121e;
          display: flex;
          font-size: 1.5rem;
          font-weight: bold;
          margin: 0;
          text-decoration: none;
          transition: all .15s ease;

          @media screen and (max-width: 670px) {
            margin-bottom: 0.5rem;
          }

          button {
            background-color: transparent;
            border: none;
            color: inherit;
            font-size: 1rem;
            margin-left: 0.5rem;
            opacity: 0;
            padding: 0;
            transition: all .15s ease;

            &:hover {
              cursor: pointer;
            }
          }

          &--dates {
            font-size: 1rem;
            font-weight: 200;
          }
        }

        a {
          &.heading {
            &:hover {
              color: #ff5879;
              cursor: pointer;
              text-decoration: none;

              button {
                opacity: 1;
              }
            }
          }
        }

        &__description {
          font-size: 0.975rem;
          line-height: 1.5rem;
          margin-top: 0.75rem;

          a {
            color: #3c71f4;
            background-color: #f4faff;
            padding: 1px 4px;
            border-radius: 3px;
            border-bottom: 1px solid #20386f12;
          }

          &--read-more {
            font-size: 15px;
          }
        }

        &__tag {
          background-color: #eceff6;
          border-radius: 4px;
          color: #20386f;
          display: inline-block;
          font-size: 0.825rem;
          margin: 0.5rem 0.5rem 0.25rem 0;
          margin-right: 0.5rem;
          padding: 4px 6px;
          text-transform: lowercase;

          &--acronym {
            font-weight: bold;
            margin-bottom: 0;
          }
        }

        &:nth-child(even) {
          background-color: #ebf4ff;

          &.list-paragraph--icon {
            background-color: transparent;
            border-left: none;
            border-bottom: 0.25rem solid #ebf4ff;

            @media screen and (max-width: 640px) {
              padding-left: 0.75rem;
            }
          }

          .list-paragraph__tag {
            background-color: #eceff6;
            color: #20386f;
          }
        }

        &__bullets {
          font-size: 0.975rem;
          line-height: 1.5rem;
          margin-top: 0.75rem;

          &--ul {
            list-style: square;
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      background-color: #283051;
      border-color: #20386f;
      padding: 0.375rem 1.75rem;

      @media screen and (max-width: 640px) {
        display: flex;
        padding: 0 0.5rem;
      }
    }

    &--main {
      background-color: #283151;
      grid-template-areas: 'header' 'body';
      grid-template-columns: unset;

      @media screen and (max-width: 640px) {
        display: flex;
        flex-direction: column;
      }
    }

    &__icon {
      align-items: center;
      background-color: #ebf4ff;
      border-radius: 0.375rem;
      color: #acbad5;
      display: flex;
      font-size: 2.5rem;
      height: 6rem;
      justify-content: center;
      transition: all .15s ease;
      width: 6rem;

      @media screen and (max-width: 960px) {
        align-items: flex-start;
        padding: 1.25rem;
      }

      @media screen and (max-width: 640px) {
        display: none;
      }
    }

    &--list {
      &:hover {
        cursor: pointer;

        .post__icon {
          background-color: #48ffd2;
          color: #0d121e;
        }

        .post__body {
          background-color: #d8f3ff;
        }
      }
    }

    &__body {
      padding: 1.25rem 0;
      transition: all .15s ease;

      h1 {
        color: #0d121e;
        font-weight: 900;
      }

      p {
        color: #0d121e;

        code {
          background-color: #0d121e;
          border-radius: 0.325rem;
          color: #5654ff;
          padding: 0.25rem 0.625rem;
          font-weight: 600;
        }
      }
    }

    &__content {
      box-sizing: border-box;
      padding: 1.25rem;
    }

    h1 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}