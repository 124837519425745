@import '../../node_modules/codemirror/lib/codemirror.css';
@import '../../node_modules/codemirror/theme/material-palenight.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://stijndv.com/fonts/Eudoxus-Sans.css');
@import './components/layout.scss';
@import './components/form.scss';
@import './components/nav.scss';
@import './components/code.scss';
@import './components/toast.scss';

$dark: #19192c;
$background: #1f1e37;
$font: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
$headerHeight: 4.75rem;
$footerHeight: 4.75rem;
$headerHeightMobile: 3.375rem;
$footerHeightMobile: 3.375rem;

html,
body {
  background-color: #070d18;
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

#root {
  min-height: 100%;
}

.layout-container {
  // background-color: #070d18;
  // background-color: #141722;
  // background-color: #ffffff;
  background-color: #f9faff;
  display: grid;
  grid-template-areas: 'header' 'body';
  grid-template-rows: $headerHeight calc(100vh - #{$headerHeight});

  &--nav-expanded {
    grid-template-areas: 'header' 'subnav' 'body';
    grid-template-rows: 4.75rem 2.6875rem calc(100vh - 7.25rem);
  }

  @media screen and (max-width: 640px) {
    grid-template-rows: $headerHeightMobile calc(100vh - #{$headerHeightMobile});
  }

  &--light {
    background-color: #f5f9ff;
  }

  &--sticky {
    .header {
      padding: 0.75rem 1.25rem;
    }
  }
}

.layout-wrapper {
  display: grid;
  grid-template-areas: 'body' 'footer';
}

.layout-nav-wrapper {
  display: flex;
  flex-direction: column;
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 3;
}

.layout-header {
  align-items: center;
  display: flex;
  grid-area: header;
  height: 10vh;
  justify-content: center;
  position: sticky;
  top: 0;
}

.layout-body {
  display: grid;
  grid-area: body;
  grid-auto-flow: column;
  grid-template-columns: 100vw;
  height: calc(100vh - #{$headerHeight});
  margin: 0;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100vw;

  @media screen and (max-width: 640px) {
    height: calc(100vh - #{$headerHeightMobile});
  }
}

.layout-section {
  height: calc(100vh - #{$headerHeight});
  overflow: scroll;
  scroll-snap-align: center;
  width: 100vw;

  @media screen and (max-width: 640px) {
    height: calc(100vh - #{$headerHeightMobile});
  }
}

.background {
  // animation-name: bg-colors;
  // animation-iteration-count: infinite;
  // animation-timing-function: linear;
  // animation-duration: 5s;
  background-color: #b9e2ff;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  margin: 0;
  min-height: 100vh;
  // padding-top: 3vw;
  z-index: 0;

  @media screen and (max-width: 960px) {
    padding-top: 0;
  }

  // &::after {
  //   background-image: url('../images/hexagon-background.jpg');
  //   background-size: cover;
  //   content: '';
  //   height: 100%;
  //   left: 0;
  //   position: fixed;
  //   top: 0;
  //   width: 100%;
  //   background-color: #0f1018;
  //   // background-color: #243cff;
  //   background-blend-mode: overlay;
  // }

  // &::before {
  //   background: #003bff;
  //   // background: #986dff8c;
  //   content: '';
  //   height: 100%;
  //   left: 0;
  //   position: fixed;
  //   top: 0;
  //   width: 100%;
  //   z-index: 1;
  //   opacity: .75;
  // }
}

@keyframes bg-colors {
  0% {
    background-color: rgb(255, 141, 235);
  }

  50% {
    background-color: rgb(131, 250, 255);
  }

  100% {
    background-color: rgb(255, 131, 131);
  }
}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100vw;
}

.code-screen {
  align-self: center;
  background-color: $dark;
  border-radius: .825rem;
  box-shadow: 0 0 33px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-height: 80vh;
  max-width: 70vw;
  width: 95%;
  margin: 2rem auto;

  .section {
    overflow: hidden;
    position: relative;
    width: 50%;

    &:first-child {
      border-bottom-left-radius: .625rem;
      border-top-left-radius: .625rem;
    }

    &:last-child {
      border-bottom-right-radius: .625rem;
      border-top-right-radius: .625rem;
    }
    
    &-header {
      align-items: center;
      background-color: $dark;
      border-bottom: 1px solid #51ffe7;
      box-sizing: border-box;
      display: flex;
      height: 4rem;
      justify-content: space-between;
      padding: 1.25rem;
      width: 100%;

      &__column {
        align-items: center;
        display: flex;
      }

      &__status {
        color: transparent;

        &--invalid {
          color: #e51758;
        }

        &--valid {
          color: #51ffe7;
        }
      }

      &__text {
        color: #7070c3;
        font-size: .85rem;
        text-indent: .75rem;
      }
    }

    &--browser {
      align-items: center;
      background-color: #292d3e;
      display: flex;
      flex-direction: column;
    }

    &--code {
      background-color: #292c3e;
    }

    &--console {
      background-color: $background;
      color: #ffffff;
      font-family: 'Courier New', Courier, monospace;

      pre {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 0;
        padding: 1.25rem;
      }
    }

    iframe {
      border: none;
      height: 100%;
      overflow: scroll;
      width: 100%;
    }
  }
}

.browser {
  &-header {
    align-items: center;
    background-color: #1687fb;
    box-sizing: border-box;
    color: rgba(255, 255, 255, .75);
    display: flex;
    height: 4rem;
    padding: 1rem;
    justify-content: center;
    width: 100%;
  }

  &-address-bar {
    background-color: rgba(0, 0, 0, .5);
    border: none;
    border-radius: .25rem;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    flex-grow: 1;
    margin-right: 1.25rem;
    padding: .5rem;
  }

  &-body {
    background-color: #ffffff;
    box-sizing: border-box;
    height: calc(100% - 4rem);
    overflow: scroll;
    padding: 0.5rem;
    width: 100%;

    
    p,h2,h3,h4,h5,h6,strong,em,b,i,u,ul,ol,li,a,img,div {all: initial;}
    h1 {
      display: block;
      font-size: 2em;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    h1 {
      display: block;
      font-size: 2em;
      margin-block-start: 0.67em;
      margin-block-end: 0.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    
    h2 {
      display: block;
      font-size: 1.5em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    
    h3 {
      display: block;
      font-size: 1.17em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    
    h4 {
      display: block;
      margin-block-start: 1.33em;
      margin-block-end: 1.33em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    
    h5 {
      display: block;
      font-size: 0.83em;
      margin-block-start: 1.67em;
      margin-block-end: 1.67em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    
    h6 {
      display: block;
      font-size: 0.67em;
      margin-block-start: 2.33em;
      margin-block-end: 2.33em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      font-weight: bold;
    }
    
    p {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    
    div {
      display: block;
    }
    
    em {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      font-style: italic;
    }
    
    strong {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      font-style: inherit;
      font-weight: bold;
    }
    
    a {
      &:-webkit-any-link {
        color: -webkit-link;
        cursor: pointer;
        font-size: inherit;
        font-style: inherit;
        text-decoration: underline;
      }
    }

    span, div {
      font-family: inherit;
      font-size: inherit;
      text-decoration: inherit;
      font-style: inherit;
      font-weight: inherit;
    }
    
    img {
      overflow-clip-margin: content-box;
      overflow: clip;
    }
  }
}

.code-check-button {
  background-color: #13ffbd;
  border: none;
  border-radius: 0.25rem;
  font-family: $font;
  padding: 0.5rem 0.75rem;
  color: #0a4c3a;
  font-weight: 800;
  text-shadow: 0 0 #c2ffee;
  font-size: .825rem;
}

.CodeMirror {
  height: auto;
  padding: 1.25rem 0.5rem;
}

.lesson {
  &-section {
    // background-color: $dark;
    // border: 1.25rem solid $dark;
    // border-radius: .325rem;
    // box-shadow: 0 0 1.125rem 0 rgba(0, 0, 0, 0.1);
    // box-sizing: border-box;
    // color: #ffffff;
    font-family: $font;
    // margin-bottom: 3rem;
    width: 100%;

    .browser-body {
      p,h2,h3,h4,h5,h6,strong,em,b,i,u,ul,ol,li,a,img,div {all: initial;}
      h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      
      h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      
      h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      
      h4 {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      
      h5 {
        display: block;
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      
      h6 {
        display: block;
        font-size: 0.67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }
      
      p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }
      
      div {
        display: block;
      }
      
      em {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        font-style: italic;
      }
      
      strong {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: bold;
      }
      
      a {
        &:-webkit-any-link {
          color: -webkit-link;
          cursor: pointer;
          font-size: inherit;
          font-style: inherit;
          text-decoration: underline;
        }
      }

      span, div {
        font-family: inherit;
        font-size: inherit;
        text-decoration: inherit;
        font-style: inherit;
        font-weight: inherit;
      }
      
      img {
        overflow-clip-margin: content-box;
        overflow: clip;
      }
    }

    &__header {
      border-left: 8px solid #bcdbff4d;
      background-color: #bcdbff1f;
      border-radius: 2px;
      box-sizing: border-box;
      padding: 1.75rem;
      width: 100%;
    }

    &__content {
      border-left: 8px solid #bcdbff4d;
      padding: 1rem 1.75rem;
    }

    &__title {
      color: #0d121e;
      font-size: 2rem;
      font-weight: 200;
      margin: 0;

      em {
        border-bottom: 3px dashed #ebf4ff;
        color: #25396a;
        font-style: normal;
        font-weight: bold;
      }
    }

    h3 {
      color: #0b141f;
      font-size: 2rem;
      font-weight: 300;

      em {
        border-bottom: 2px solid #1687fb;
        font-style: normal;
      }
    }

    &__text, p {
      color: #0b141f;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 2rem;

      a {
        color: #ff5879;
      }

      strong {
        color: #25396a;
      }

      code {
        font-size: 0.875rem;
        font-weight: 100;
        white-space: nowrap;
      }
    }

    ul {
      color: #0b141f;
      font-size: 1.325rem;
      line-height: 3rem;
      list-style: circle;

      code {
        font-size: 1rem;
      }
    }

    code {
      border-radius: 0.325rem;
      padding: .325rem 0.5rem;
      background-color: #f7fbff;
      border: 1px solid #f7fbff;
      border-radius: 0.325rem;
      color: #ff5879;
      padding: 0.325rem 0.5rem;
      font-weight: bold;
    }
  }

  &-container {
    align-items: center;
    box-sizing: border-box;
    // color: #ffffff;
    display: flex;
    flex-direction: column;
    font-family: $font;
    justify-content: center;
    margin: 0 auto;
    width: 90%;

    @media screen and (max-width: 640px) {
      width: 100%;
    }
    // overflow: scroll;
    // width: 80%;
    // z-index: 1;
  }
}

.code-demo {
  background-color: #ffffff;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
}

.code-view {
  &-container {
    border-radius: 0.75rem;
    filter: brightness(1.375);
    margin-top: 1.25rem;
    overflow: hidden;
    width: 100%;

    .cm-s-material-palenight {
      &.CodeMirror {
        background-color: #283051;
      }

      .CodeMirror-gutters {
        background-color: #283051;
      }
    }

    .cm-s-material-palenight.CodeMirror {
      background-color: #283051;
    }
  }
}

@keyframes code-view-border {
  0% {
    border-color: rgb(152, 114, 255);
  }

  12% {
    border-color: rgb(0, 117, 255);
  }

  25% {
    border-color: rgb(114, 255, 232);
  }

  37% {
    border-color: rgb(114, 255, 130);
  }

  50% {
    border-color: rgb(249, 114, 114);
  }

  62% {
    border-color: rgb(255, 70, 129);
  }

  75% {
    border-color: rgb(255, 114, 114);
  }

  87% {
    border-color: rgb(255, 114, 114);
  }

  100% {
    border-color: rgb(255, 114, 179);
  }
}

.about {
  &-intro {
    width: 90vw;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 1.25rem;
    font-family: Kumbh Sans, sans-serif;

    &__summary {
      border: 1px solid #edeff7;
      border-radius: 0.75rem;
      height: fit-content;
      overflow: hidden;
    }

    &--horizontal {
      align-items: flex-start;
      column-gap: 10%;
      display: grid;
      grid-template-columns: 50% 40%;
      justify-content: space-between;

      @media screen and (max-width: 480px) {
        grid-template-columns: auto;
        padding: 1.25rem 0.5rem;
      }
    }

    &__title {
      font-size: 2.125rem;
      letter-spacing: -1px;
      // color: white;
      color: #20386f;
      display: flex;
      flex-direction: column;
      font-weight: 700;
      box-sizing: border-box;
      padding-left: 1.25rem;
      border-left: 5px solid #3b6ff3;
      justify-content: center;
      position: relative;
      margin-bottom: 6rem;

      @media screen and (max-width: 480px) {
        font-size: 1.25rem;
        line-height: 1.375rem;
      }

      .name {
        &-bracket {
          color: #326cff;
          padding: 0 0.375rem;

          &:first-child {
            padding-right: 0.25rem;
          }

          &:last-child {
            padding-left: 0.25rem;
          }
        }

        &-comment {
          color: #505e7f;
          font-size: 1.75rem;
          font-weight: 500;
          position: relative;

          &--name {
            color: #326cff;
          }

          @media screen and (max-width: 480px) {
            font-size: 1.125rem;
          }
        }
      }
    }

    p {
      background-color: #ffffff;
      border-radius: 0;
      box-sizing: border-box;
      color: #20386f;
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: -.25px;
      line-height: 1.5rem;
      margin: 0;
      padding: 1.5rem 1.25rem;
      width: 100%;

      &:nth-child(even) {
        background-color: #20386f;
        color: #ffffff;
        width: 100%;
      }
    }

    &-nav {
      // background-color: #090c18;
      // background-color: #f1f8ff;
      align-items: center;
      background-color: #ffffff;
      border-bottom: 1px solid #aebbd93b;
      box-sizing: border-box;
      display: flex;
      height: 100%;
      justify-content: space-between;
      left: 0;
      padding: 1.325rem 1.75rem;
      position: sticky;
      top: 0;
      width: 100vw;
      z-index: 3;

      @media screen and (max-width: 640px) {
        align-items: center;
        justify-content: space-between;
        padding: 0;
      }

      &--expanded {
        border-bottom: none;
        padding-bottom: 0;
      }

      &__mobile-wrapper {
        align-items: center;
        display: flex;
        height: 100%;
        width: fit-content;

        @media screen and (max-width: 640px) {
          background-color: white;
          justify-content: space-between;
          padding: 0 0.75rem;
          position: relative;
          width: 100%;
          z-index: 1;
        }
      }

      .logo {
        align-items: center;
        color: #283151;
        display: flex;
        font-family: eudoxus sans, sans-serif;
        font-size: 1.625rem;
        font-weight: 900;
        letter-spacing: -0.5px;
        margin: 0;

        .breadcrumb {
          color: #3b6ff3;
          font-size: 0.875rem;
          font-weight: 500;
        }

        @media screen and(max-width: 640px) {
          font-size: 1.125rem;
        }
      }

      .nav {
        position: relative;

        @media screen and (max-width: 640px) {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: space-around;
          position: absolute;
          top: -$headerHeightMobile;
          text-align: center;
          transition: all 0.5s ease;
          width: 100%;
          z-index: 0;
        }

        &--visible {
          @media screen and (max-width: 640px) {
            background-color: white;
            // border-bottom: 1px solid #aebbd93b;
            border-bottom: 5px solid #edeff7;
            box-sizing: border-box;
            height: calc(100% - 5px);
            left: 0;
            padding: 0 0.75rem;
            top: 100%;
          }
        }

        .about-nav-item {
          background-color: transparent;
          border: none;
          color: #9caace;
          display: inline-flex;
          font-family: inherit;
          font-weight: 500;
          font-size: .9375rem;
          letter-spacing: -.25px;
          margin: 0 0.5rem;
          padding: 0.5rem 0;
          text-decoration: none;
          transition: all .15s ease;

          @media screen and (max-width: 640px) {
            display: inline-block;
            margin: 0;
            text-align: center;
            width: fit-content;
          }

          &--nested {
            display: none;
          }

          @media screen and (max-width: 480px) {
            padding: 0.875rem 0;
          }

          &:hover {
            color: #20386fc9;
            cursor: pointer;
          }

          &--active {
            color: #20386f;
          }

          .child {
            font-size: 0.75rem;
            font-weight: 300;
            margin-left: 0.25rem;
            margin-top: 2px;

            &::before {
              content: '\2192';
              margin-right: 0.25rem;
            }
          }

          &__underline {
            display: flex;
            height: 2px;
            transition: all .25s ease;

            .content {
              background-color: #20386f;
              display: block;
              height: 100%;
              width: 100%;
            }

            @media screen and (max-width: 480px) {
              bottom: -5px;
              height: 5px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}



.subnav {
  align-items: flex-end;
  background-color: #ffffff;
  border-bottom: 2px solid #edeff7;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  margin-left: auto;
  padding: 0 1.25rem;
  position: static;
  text-align: right;
  width: 100%;

  &-item {
    border-bottom: 0.125rem solid transparent;
    box-sizing: border-box;
    color: #9caace;
    display: inline-block;
    font-family: inherit;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: -0.25px;
    padding: 0.275rem 0.75rem;
    position: relative;
    text-decoration: none;

    .tree-node {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -100%);
      display: block;
      border-top: 1px solid #ebeff6;
      background-color: #ebeff6;
      height: 0.375rem;
      width: 1px;
    }

    &:nth-child(2) {
      // transform: translateX(-50%);
      .tree-node {
        background-color: transparent;
        border-left: 1px solid #ebeff6;
        border-top: 1px solid #ebeff6;
        height: 0.375rem;
        width: 0.375rem;
      }
    }

    &:last-child {
      // transform: translateX(50%);

      .tree-node {
        background-color: transparent;
        border-right: 1px solid #ebeff6;
        border-top: 1px solid #ebeff6;
        height: 0.375rem;
        width: 0.375rem;
      }
    }

    &--active {
      border-color: #3b6ff3;
    }
    
    .tree-node {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -100%);
      display: block;
      height: 0.375rem;
      width: 0.375rem;
      border-left: 1px solid #ebeff6;
      border-top: 1px solid #ebeff6;
    }
  }

  nav {
    height: 100%;
    padding: 0 0.75rem;
    width: fit-content;
  }

  &-tree {
    display: flex;
    margin-top: 7px;
    width: 100%;

    .node {
      border: none;
      border-top: 1px solid #eceff6;
      display: flex;
      height: 0.375rem;
      justify-content: center;
      width: 0.375rem;

      &> .node__pointer {
        background-color: #eceff6;
      }

      &--active {
        border-color: #3572ef;

        &> .node__pointer {
          background-color: #3572ef;
        }
      }

      &--fill {
        display: flex;
        flex-grow: 1;
      }

      &--left {
        justify-content: flex-start;
      }

      &--right {
        justify-content: flex-end;
      }

      &__pointer {
        height: 100%;
        width: 1px;
      }
    }
  }
}

.chatbot {
  width: 300px;
  height: 440px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, .11) 4px 0px 8px 6px;
  display: flex;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  z-index: 10;
  box-sizing: border-box;
  padding: 1rem;
  background-color: #283151;
  transition: all .3s ease;

  h1 {
    margin: 0;
    font-family: 'Kumbh Sans';
    font-size: 1.25rem;
    letter-spacing: -1.5px;
    color: #9ea7cceb;
    font-weight: 500;
    border-bottom: 1px solid #3d71f540;
    width: fit-content;
  }

  &--minimized {
    height: 44px;
    padding: 0.625rem 1rem;
    overflow: hidden;

    h1 {
      color: #9ea7cceb;
      font-size: 1.125rem;
    }
  }

  &__close-button {
    font-size: 1.25rem;
    color: #ffffff;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 4px;
    top: 6px;
    height: 2rem;
    width: 2rem;
  }

  &__placeholder {
    color: #acc4fca3;
    font-size: 15px;
    font-weight: 500;
    font-family: 'eudoxus sans';
    box-sizing: border-box;
    padding: 0 1.25rem;

    ul {
      padding-left: 1.25rem;
    }
  }

  &__container {
    display: grid;
    width: 100%;
    grid-template-rows: 1fr 10fr 1fr;

    .input-form {
      display: grid;
      grid-template-columns: 11fr 1fr;

      .input {
        background-color: #4d5f8c;
        border: none;
        box-sizing: border-box;
        color: rgb(181, 199, 255);
        padding: 0 0.75rem;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        font-family: system-ui;
        font-weight: 500;
        font-size: 13px;
        outline: 0;

        &::placeholder {
          color: rgb(181, 199, 255);
        }
      }

      &-button {
        background-color: #4d5f8c;
        border: none;
        color: #b5c7ff;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
      }
    }

    .thread {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      padding-bottom: 1.25rem;
      padding-top: 0.75rem;
      scrollbar-width: none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .row {
        display: flex;

        &--user {
          justify-content: flex-end;
        }

        &--bot {
          justify-content: flex-start;
        }
      }

      .message {
        align-items: center;
        margin-top: 0.5rem;
        border-radius: .25rem;
        padding: 0.75rem;
        resize: none;
        color: #acc3ff;
        display: flex;
        font-family: system-ui;
        font-size: .925rem;
        box-sizing: border-box;
        font-weight: 400;
        height: fit-content;
        font-weight: 500;
        display: flex;
        border-radius: 30px;
        padding: 7px 15px;
        font-size: 0.875rem;
        min-height: 2rem;
        min-width: 1rem;
        overflow-x: hidden;
        width: fit-content;

        &--user {
          background-color: #3c70f4;
          color: #f2f5ff;
        }

        &--bot {
          background-color: #4d5f8c;
          color: #d1ddff;
        }

        .typing-loader,
        .typing-loader::before,
        .typing-loader::after {
          width: 6px;
          aspect-ratio: .5;
          display: grid;
          background: radial-gradient(#d1ddff 68%, #d1ddff00 72%) center/100% 50% no-repeat;
          animation: typing-ellipsis 1.25s infinite linear calc(var(--_s,0)*.14s);
          transform: translate(calc(var(--_s,0)*150%));
        }

        .typing-loader {
          &::before,
          &::after {
            content: '';
            grid-area: 1/1;
          }

          &::before {--_s: -1}
          &::after {--_s: 1}
        }
      }
    }
  }
}

@keyframes typing-ellipsis {
  20% {background-position: top;}
  40% {background-position: bottom;}
  60% {background-position: center;}
}