$systemFont: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

.form {
  .registration-wrapper {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 840px) {
      flex-direction: column;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    &--confirmation {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  input {
    &.registration-input {
      background-color: #1c2436c7;
      backdrop-filter: blur(10px);
      border: 1px solid #1c2436;
      border-radius: 0.325rem;
      box-sizing: border-box;
      color: #a9a9e1;
      font-family: $systemFont;
      font-size: .95rem;
      font-weight: 500;
      margin-bottom: 1rem;
      padding: 1.25rem 1.5rem;
      width: 360px;

      @media screen and (max-width: 840px) {
        width: 100%;
      }
    
      &::placeholder {
        color: #797aa5;
      }
    
      &:focus {
        outline: 0;
      }

      &--confirmation {
        border: 1px solid transparent;
        font-size: 6rem;
        font-weight: 300;
        padding: 2rem 1.5rem;
        text-align: center;
        width: calc(100%/7);

        &--valid {
          border-color: #48fed2;
          color: #48fed2;
        }

        &--invalid {
          border-color: #fe4560;
          color: #fe4560;
        }
        // Prop: valid
        // border-color: ${props => props.valid === true ? '#48fed2' : props.valid === false ? '#fe4560' : 'transparent'};

        @media screen and (max-width: 1180px) {
          font-size: 5.75rem;
          padding: 1.5rem;
        }

        @media screen and (max-width: 1140px) {
          font-size: 5rem;
          padding: 1.25rem;
        }

        @media screen and (max-width: 960px) {
          font-size: 4.75rem;
          padding: 1.125rem;
        }

        @media screen and (max-width: 840px) {
          font-size: 4.25rem;
          padding: 1rem;
        }

        @media screen and (max-width: 780px) {
          font-size: 4.125rem;
          padding: .825rem;
        }

        @media screen and (max-width: 740px) {
          font-size: 4rem;
          padding: .75rem;
        }

        @media screen and (max-width: 600px) {
          font-size: 2.325rem;
          padding: 0.5rem 0.625rem;
        }
      }
    }
  }

  button {
    align-items: center;
    background: linear-gradient(27deg, #ff2a78, #fe5252, #fe9134);
    border: 0;
    border-radius: 0.325rem;
    box-sizing: border-box;
    color: #ffffff;
    display: flex;
    font-family: $systemFont;
    font-size: .975rem;
    font-weight: 500;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: calc(1rem + 1px);
    width: 360px;

    @media screen and (max-width: 840px) {
      width: 100%;
    }
  }
}